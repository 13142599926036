.shop-header {
  display: block;
  padding-top: 40px;
  padding-bottom: 20px;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 1;
}
.shop-header .thumbnail-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
img.shop-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: inline-block;
}
div.shop-header-details {
  display: inline-block;
  margin-left: 10px;
}
div.shop-header-details h1 {
  margin-bottom: 5px;
}
div.shop-header-details h1, div.shop-header-details p {
  text-align: left;
}
img.shop-cover {
  display: none;
}
h2.shop-header {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
div.shop-info {
  margin-top: -50px;
  margin-bottom: 40px;
}
div.shop-sticky-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  opacity: 0;
  z-index: 0;
  display: none;
}
div.shop-sticky-header.sticky {
  position: fixed;
  opacity: 1;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
}
div.shop-sticky-header.sticky .shop-header-info {
  animation: fadeIn .5s;
}
.shop-header-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shop-header-info .shop-thumbnail {
  width: 100px;
  height: 100px;
  display: flex;
  margin: 0px;
}
.shop-header-info .shop-header-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}
img.shop-cover {
  width: 100vw;
  height: auto;
  display: block;
  margin: auto;
  top: 0;
  visibility: visible;
  opacity: 1;
}
div.shop-background-full {
  max-height: 200px;
  overflow: hidden;
}
div.shop-header-details h1, div.shop-header-details p {
  text-align: center;
}
.shop-header .thumbnail-header {
  display: block;
}
img.shop-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}
div.gallery-modal div.MuiPaper-root {
  display: flex;
  background: black;
  justify-content: center;
}
.shop-header-box {
  max-width: 100%;
  overflow: hidden;
  max-height: 310px;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@media(min-width: 900px) {
  img.shop-thumbnail {
    width: 190px;
    height: 190px;
  }
  div.shop-background-full {
    max-height: 300px;
    overflow: hidden;
  }
  div.shop-info {
    margin-top: -95px;
  }
}
