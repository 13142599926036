.chat-header {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--lightGrey);
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 60px;
  visibility: hidden;
}
.chat-header h1 {
  margin-bottom: 20px;
}
button.chat-back {
  display: none;
}
div.chat-nav {
  padding: 20px 0px;
  border-bottom: 1px solid var(--lightGrey)
}
div.chat-appointment-box {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
}
@media(max-width: 640px) {
  .chat-container {
    display: block !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  }
  .chat-header {
    visibility: visible !important;
  }
  button.chat-back {
    display: flex;
  }
  div.chat-div {
    height: calc("100vh - 180px") !important;
  }
}
@media(min-width: 640px) {
  div.message-header {
    display: none;
  }
  div.chat-container {
    padding: 20px;
  }
}
