.phone-input {
  width: 100% !important;
}

.phone-input .special-label {
  display: none;
}

.phone-input .react-tel-input .form-control {
  width: 100%;
  height: 44px;
  border: 0.5px solid var(--lightOutlineGrey) !important;
  border-radius: 10px !important;
  border-radius: 0;
  margin-left: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
}

.phone-input .react-tel-input .form-control:focus-within {
  outline: 1.5px solid var(--darkGrey) !important;
  border-color: transparent !important;
}

.phone-input .react-tel-input .form-control:focus-within .flag-dropdown {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.phone-input .flag-dropdown {
  z-index: 7 !important;
  border: none;
  border-radius: 0;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top: 0.5px solid var(--lightOutlineGrey) !important;
  border-bottom: 0.5px solid var(--lightOutlineGrey) !important;
  background-color: transparent;
  left: 1px;
}

.phone-input .flag-dropdown:hover,
.phone-input .flag-dropdown.open,
.react-tel-input .flag-dropdown .selected-flag {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
