body {
  --primary: #ff3008;
  --primaryLight: #ffd6ce;
  --secondary: #00a86b;
  --accent: #ceeee3;
  --darkGrey: #333333;
  --medGrey: #666666;
  --lightGrey: #f5f5f5;
  --outlineGrey: #939393;
  --lightOutlineGrey: #bdbdbd;
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

p.link {
  color: var(--primary);
  margin-top: 5px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

button.secondary,
a.secondary {
  border: 1px solid var(--darkGrey);
  color: var(--darkGrey);
  padding: 10px 40px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.125rem;
}
button.secondary:hover,
a.secondary:hover {
  border: 1px solid var(--darkGrey);
  color: var(--darkGrey);
  background: transparent;
  padding: 10px 40px;
}

button.primary,
a.primary {
  background-color: var(--primary);
  box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
  padding: 10px 40px;
  border: 1px solid var(--primary);
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.125rem;
}
button.primary:hover,
a.primary:hover {
  background-color: #c30000;
  border: 1px solid #c30000;
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
button.primary.disabled,
a.primary.disabled {
  background-color: var(--lightGrey);
  border-color: var(--lightGrey);
  color: var(--outlineGrey);
  box-shadow: none;
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

div.MuiContainer-maxWidthLg {
  max-width: 1120px;
  padding: 0px;
}

button.help-fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

/* Shop page styling */
img.thumbnail {
  width: 190px;
  height: 190px;
  border-radius: 100px;
  background: #000;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}
.shop-header h1,
.shop-header p {
  text-align: center;
}
.shop-grid h2 {
  text-align: center;
  margin-bottom: 60px;
}
div.login form {
  padding: 0px;
  border-radius: 10px;
}
div.artist-card img {
  margin-bottom: 5px;
  max-width: 100%;
  border-radius: 500px;
  aspect-ratio: 1;
  max-height: 320px;
}
@media (max-width: 900px) {
  .artist-modal-box {
    padding: 64px 10px !important;
  }
}
.appointment-list-container {
  padding: 124px 16px;
}
.artist-card a {
  text-align: left;
  display: block;
  margin-top: 5px;
}
button.close-modal {
  background: none;
  border: none;
}
.modal-header-confirmation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.data-error,
.loading-container {
  padding-top: 60px !important;
  max-width: 75% !important;
  display: block !important;
  margin: auto !important;
}
.data-error h1,
.data-error p,
.loading-container h1,
.loading-container p {
  text-align: center;
}
img.cover-image {
  width: 1440px;
  height: 400px;
  width: stretch;
}
div.artist-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.about-artist p {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
div.artist-card-div:hover .about-artist {
  opacity: 1;
}
.about-artist {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000;
}
button.next.done,
a.next.done {
  background-color: var(--primary);
  box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -moz-transition:
    all 0.2s,
    -moz-transform 0.5s;
  -o-transition:
    all 0.2s,
    -o-transform 0.5s;
  -webkit-transition:
    all 0.2s,
    -webkit-transform 0.5s;
  transition:
    all 0.2s,
    transform 0.5s;
}
button.next.done.MuiLoadingButton-root,
a.next.done {
  box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -moz-transition:
    all 0.2s,
    -moz-transform 0.5s;
  -o-transition:
    all 0.2s,
    -o-transform 0.5s;
  -webkit-transition:
    all 0.2s,
    -webkit-transform 0.5s;
  transition:
    all 0.2s,
    transform 0.5s;
}
button.next.done:hover,
a.next.done:hover {
  background-color: #c30000;
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
a.next.done {
  display: block;
  height: 48px;
  padding: 0 60px;
  transform: translateY(10px);
  display: flex;
}
@media (min-width: 900px) {
  div.shop-grid.scroll {
    padding-top: 560px;
    z-index: 0;
  }
  div.login form {
    border: 1px solid var(--outlineGrey);
    padding: 60px;
  }
  div.shop-grid.final {
    margin-top: 267px;
  }
  div.artist-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  div.shop-grid {
    padding-top: 0px;
  }
}
div.shop-grid button {
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat;
  text-align: left;
  display: block;
  padding: 0px;
  z-index: 0;
}
h3.error {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
div.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  width: 100vw;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
div.data-error h1,
div.loading-container h1 {
  margin-bottom: 20px;
}
p.loading-text {
  margin-top: 20px;
  text-align: center;
}
p.tos {
  margin-top: 0px;
  text-align: left;
  color: var(--outlineGrey);
}
p.tos a {
  font-weight: 600;
  color: var(--medGrey);
  text-decoration: none;
}
h2.modal-header {
  text-align: center;
  margin-bottom: 20px;
}
p.modal-body {
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 600px) {
  div.form-header-title {
    padding: 20px 16px;
  }
}
label.service-label span.MuiRadio-root {
  display: none;
}
label.service-label {
  padding: 20px;
  border: 1px solid var(--outlineGrey);
  border-radius: 10px;
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.review-form {
  width: calc(100vw - 64px);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 60px;
}
div.review-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
div.review-location {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.review-header h1 {
  text-align: center;
}
div.review-header p {
  text-align: center;
  color: var(--medGrey);
}
button.text-btn,
a.text-btn {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  text-decoration: underline;
  line-height: 0.9rem;
  padding: 0px;
}
