:root {
  --PhoneInputCountryFlag-height: 0.9em;
}

button.next {
  display: block;
  margin: auto;
  margin-top: 60px;
  padding: 0px 60px;
  height: 48px;
  background-color: var(--lightGrey);
  color: var(--outlineGrey);
  box-shadow: none;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}
button.next:hover {
  background-color: var(--lightGrey);
  color: var(--outlineGrey);
  box-shadow: none;
}
button.next.done,
a.next.done {
  background-color: var(--primary);
  color: #fff;
  box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -moz-transition:
    all 0.2s,
    -moz-transform 0.5s;
  -o-transition:
    all 0.2s,
    -o-transform 0.5s;
  -webkit-transition:
    all 0.2s,
    -webkit-transform 0.5s;
  transition:
    all 0.2s,
    transform 0.5s;
}
button.next.done.MuiLoadingButton-root,
a.next.done {
  box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -moz-transition:
    all 0.2s,
    -moz-transform 0.5s;
  -o-transition:
    all 0.2s,
    -o-transform 0.5s;
  -webkit-transition:
    all 0.2s,
    -webkit-transform 0.5s;
  transition:
    all 0.2s,
    transform 0.5s;
}
button.next.done:hover,
a.next.done:hover {
  background-color: #c30000;
  color: #fff;
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
svg.form-back {
  position: absolute;
  top: 0;
  left: 0;
}
.MuiInputBase-root {
  border-radius: 10px;
}
fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--outlineGrey) !important;
}
div.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border: 1.5px solid var(--darkGrey) !important;
}
div.MuiSelect-select {
  border: 1px solid var(--outlineGrey);
}
input,
textarea {
  color: var(--darkGrey) !important;
}
label.MuiFormLabel-root {
  color: var(--medGrey);
}
label.MuiFormLabel-root.Mui-focused {
  color: var(--darkGrey);
}
div.MuiInputAdornment-outlined {
  padding-top: 12px;
}
div.textarea {
  max-width: 600px;
}
div#location,
div#skinTone,
div#city,
div#piercingLocationId,
div#piercingLocationCategory,
div#tattoo-service-type {
  min-width: 254px;
  border: none;
  color: var(--darkGrey);
}
div.radio-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
label.radio-pill, div.radio-pill {
  width: 100%;
  max-width: 600px;
  height: 58px;
  background: #fff;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid var(--outlineGrey);
  display: flex;
  justify-content: flex-start;
}
label.radio-pill.checked, div.radio-pill.checked {
  background: var(--accent);
  border: 1px solid var(--darkGrey);
}
img.size-icon {
  width: 40px;
  display: block;
  margin: auto;
}
.size-label-text {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
div#size-radio-group span.MuiRadio-root {
  display: none;
}
div.review-section {
  max-width: 568px;
  padding: 0px 16px;
}
.back {
  position: absolute;
  z-index: 1300;
}
button.review-link {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0px;
  min-width: 0px;
  font-weight: 400;
  text-decoration: underline;
  font-size: 14px;
}
.review-item {
  position: relative;
}
.review-container {
  margin-bottom: 120px;
}
form {
  position: relative;
}
.input-reg label.Mui-focused {
  color: var(--outlineGrey);
}
div.input-reg .MuiInputLabel-root {
  line-height: 60px;
  top: -15px;
}
div.input-reg div.Mui-focused fieldset {
  border-color: var(--lightGrey) !important;
}
div.input-reg .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--lightGrey) !important;
}
.input-reg .MuiOutlinedInput-notchedOutline {
  border-color: var(--lightGrey) !important;
  border-width: 1px !important;
  outline: 0 !important;
  border: none;
}
div.input-reg .MuiInputLabel-root.MuiInputLabel-shrink {
  top: 20px !important;
  line-height: 18px !important;
}
div.input-reg .MuiOutlinedInput-root {
  border-radius: 10px;
  border: 1px solid var(--lightGrey);
}
.other-details-div {
  margin: auto;
  max-width: 400px;
}
div.other-details-div .input-reg input {
  width: 370px;
}
div.review-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
div.imagePreviews img {
  object-fit: cover;
  border-radius: 5px;
  max-height: 265px;
}
.metadata-bar {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-top: 1px solid black;
  border-radius: 0px 0px 5px 5px;
  background-color: var(--lightGrey);
  gap: 10px;
}
.metadata-bar p {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.image-input input {
  display: none;
}
label.image-label {
  width: calc(100% - 32px);
  max-width: 600px;
  border: 1px solid var(--outlineGrey);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 16px;
}
button.remove-image {
  display: block;
  margin: auto;
}
form {
  padding-bottom: 165px;
}
div.review-item img.image-upload {
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 20px;
}
div.phone-input {
  width: 300px;
  display: block;
  margin: auto;
}
input.PhoneInputInput:focus {
  border: 1.5px solid var(--darkGrey);
  outline: 0px;
}
.PhoneInputCountry {
  margin-right: 10px;
  border: 1px solid var(--lightOutlineGrey);
  border-radius: 5px;
  aspect-ratio: 1;
  padding-inline: 8px;
}
.PhoneInputCountry select:focus {
  border: 1.5px solid var(--darkGrey);
}
.PhoneInputCountryIcon--border {
  box-shadow: none;
}
.multi-input-container .PhoneInputCountry {
  border: 1px solid var(--lightOutlineGrey);
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
}
.multi-input-container .PhoneInputCountryIconUnicode,
.multi-input-container .PhoneInputCountrySelectArrow {
  font-size: 1.125rem;
}
form#login-form div.phone-input-container {
  position: relative;
  margin: 20px 0px;
}
div.phone-input-container {
  position: relative;
}
input.PhoneInputInput {
  border: 1px solid var(--lightOutlineGrey);
  color: var(--darkGrey);
  height: 56px;
  padding: 0px 15px 0px 77px;
  border-radius: 5px;
  font-size: 1.125rem;
  font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 400;
  outline: 0px;
  background-color: transparent;
}
span.country-code {
  position: absolute;
  width: 60px;
  font-size: 1.125rem;
  font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  height: 40px;
  left: 75px;
  top: 9px;
  border-radius: 5px 0px 0px 5px;
  border-right: 1px solid var(--lightOutlineGrey);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--darkGrey);
}
.birthday-inputs {
  display: flex;
}
.month-input .MuiOutlinedInput-root,
.month-input.MuiOutlinedInput-root {
  border-radius: 5px 0px 0px 5px;
}
.day-input .MuiOutlinedInput-root,
.day-input.MuiOutlinedInput-root {
  border-radius: 0px;
}
.year-input .MuiOutlinedInput-root,
.year-input.MuiOutlinedInput-root {
  border-radius: 0px 5px 5px 0px;
}
.day-input {
  margin-left: -1px !important;
  margin-right: -1px !important;
}
.multi-input-container span.country-code {
  left: 84px;
}
.multi-input-container input.PhoneInputInput {
  padding: 0px 15px 0px 77px;
}
p.error {
  text-align: center;
  margin-top: 20px;
  color: var(--primary);
}
input.input-code {
  font-weight: 600;
  color: var(--darkGrey);
  font-family: 'Montserrat', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
  margin: 0px 5px;
  text-align: center;
  border: 0px;
  border-bottom: 1px solid var(--darkGrey);
  border-radius: 0px;
  font-size: 1.625rem;
  outline: 0px;
  max-width: 30px;
  padding: 10px 0px;
}
input.input-code:focus {
  border-color: var(--primary);
}
p.sub {
  text-align: center;
  margin-bottom: 60px;
}
.review-container {
  margin-top: 40px;
}
div.MuiInputAdornment-outlined {
  line-height: 22px;
  padding-top: 27px;
  height: 22px;
  padding-bottom: 9px;
}
.review-section a {
  position: absolute !important;
  top: 0 !important;
}
div.multi-input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: auto;
}
.birthday-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.image-upload-preview {
  position: relative;
}
button.remove-image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 34px;
  height: 34px;
  background: var(--lightGrey);
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 0px;
}
label.birthday-label {
  color: var(--darkGrey);
  font-weight: 600;
  margin-left: 1px;
  font-size: 14px;
}
.birthday-header svg {
  color: var(--darkGrey);
}
div.birthday-input {
  width: 100%;
}
div.birthday-input .MuiInputBase-root {
  width: 100%;
}
.birthday-header button {
  padding-top: 0px;
  padding-bottom: 0px;
}
div#year,
div#day,
div#month {
  border: none;
}
div.final-details-container
  div.multi-input-container
  .react-tel-input
  .input-reg {
  width: 100% !important;
  border-radius: 5px !important;
}
div.final-details-container
  div.multi-input-container
  .react-tel-input
  .special-label {
  display: flex;
  color: var(--outlineGrey);
}
div.final-details-container div.phone-input {
  width: 100%;
}
div.size-label {
  display: flex;
}
label.size-label {
  padding: 20px;
  border: 1px solid var(--outlineGrey);
  border-radius: 10px;
  margin: 0;
  max-width: 340px;
}
label.size-label.checked {
  background: var(--accent);
  border: 1px solid var(--darkGrey);
}
div.image-upload-preview img {
  object-fit: cover;
  width: 100%;
  height: calc((100vw - 42px) / 2);
  max-height: 186px;
}
@media (max-width: 600px) {
  p.sub {
    margin-bottom: 40px;
  }
  button.next {
    margin-top: 40px;
  }
  .input-reg {
    max-width: 90%;
  }
  div.imagePreviews {
    justify-content: center;
  }
  form {
    padding-top: 0px;
  }
  .modal-box {
    width: 80vw !important;
    margin: auto;
  }
}
div.modal-box svg.MuiSvgIcon-colorSuccess.MuiSvgIcon-fontSizeLarge {
  width: 48px;
  height: 48px;
}
div.modal-box div {
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.budget {
  width: fit-content;
  display: block;
  margin: auto;
}
.PhoneInputCountryIconUnicode {
  font-size: 2rem;
}
.PhoneInputCountrySelectArrow {
  font-size: 2rem;
  border-color: var(--darkGrey);
  opacity: 1;
  margin-top: -5px;
}
.form-header-title {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
}
p.form-sub-header {
  margin-top: 5px;
}
div.input-container {
  width: calc(100% - 32px);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 16px;
}
div.input-container.no-gap {
  gap: 0px;
}
@media (max-width: 900px) {
  .back {
    margin-bottom: 0px;
    margin: auto;
    margin-top: 20px;
    position: relative;
  }
}
@media (min-width: 600px) {
  div.input-reg.other-notes {
    max-width: 100%;
  }
}
.code-input-container {
  display: flex;
  justify-content: space-around;
  width: 75%;
  margin: auto;
}
