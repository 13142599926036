/* declare color vars */

:root {
  --colored-banner-title: #333333;
  --colored-banner-warning-bg: #fdf8c9;
  --colored-banner-warning-text: #b13600;
  --colored-banner-warning-border: #fbd992;
  --colored-banner-info-bg: #eff6f9;
  --colored-banner-info-text: #1c8cbf;
  --colored-banner-info-border: #1c8cbf;
}

/* base styling */

.colored-alert {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  gap: 0.75rem;
}

.colored-alert.MuiAlert-standard {
  border-radius: 10px;
}

.colored-alert.MuiAlert-standard h3,
.colored-alert.MuiAlert-standard p {
  font-size: 0.875rem;
  margin: 0;
}

.colored-alert.MuiAlert-standard h3 {
  font-weight: 700;
  color: var(--colored-banner-title);
}

.colored-alert.MuiAlert-standard p {
  margin-top: 0.25rem;
}

.colored-alert.MuiAlert-standard .MuiAlert-icon {
  margin: 0;
}

/* Warning Styling */

.colored-alert.MuiAlert-standardWarning {
  background-color: var(--colored-banner-warning-bg) !important;
  border: 1px solid var(--colored-banner-warning-border) !important;
}

.colored-alert.MuiAlert-standardWarning p {
  color: var(--colored-banner-warning-text);
}

.colored-alert.MuiAlert-standardWarning .MuiAlert-icon,
.colored-alert.MuiAlert-standardWarning .MuiAlert-action svg {
  color: var(--colored-banner-warning-text);
}

/* Info Styling */

.colored-alert.MuiAlert-standardInfo {
  background-color: var(--colored-banner-info-bg) !important;
  border: 1px solid var(--colored-banner-info-border) !important;
}

.colored-alert.MuiAlert-standardInfo p {
  color: var(--colored-banner-info-text);
}

.colored-alert.MuiAlert-standardInfo .MuiAlert-icon,
.colored-alert.MuiAlert-standardInfo .MuiAlert-action svg {
  color: var(--colored-banner-info-text);
}

@media (max-width: 600px) {
  .colored-alert {
    gap: 0.5rem;
  }

  .MuiAlert-action {
    padding-left: 4px !important;
  }
}
