img.artist-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: inline-block;
}
div.artist-header-details {
    display: inline-block;
    margin-left: 10px;
}
.artist-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.progress-bar {
  position: fixed;
  top: 63px;
  padding: 20px 16px;
  border-bottom: 1px solid var(--lightGrey);
  width: calc(100% - 32px);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  background: #fff;
  z-index: 2;
}
.progress-steps {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.step-circle {
    height: 26px;
    width: 26px;
    color: var(--medGrey);
    background: var(--lightGrey);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    margin: auto;
}
.step-circle.done {
    color: #FFF;
    outline: none;
    background-color: var(--accent);
}
.step-circle h2 {
    color: var(--outlineGrey);
}
.step-circle.done h2 {
    color: #FFF;
}
.step-line.done {
    background-color: var(--primary);
}
.step-label p {
    text-align: center;
    margin-top: 5px;
    color: var(--medGrey);
    text-transform: capitalize;
}
.progress-line {
    position: absolute;
    top: 31px;
    left: 26px;
    width: calc(100% - 55px);
    height: 4px;
    background-color: var(--lightGrey);
    z-index: 0;
}
.step {
    z-index: 1;
}
div.artist-header-details h1 {
    margin-bottom: 5px;
}
.step-label {
  text-transform: capitalize;
}
@media(min-width: 600px) {
  .progress-bar {
    margin: auto;
    width: 568px;
    top: 63px;
  }
}
